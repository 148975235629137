var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"40%"},on:{"click:outside":_vm.clickOutside},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({attrs:{"id":"addLot"},on:{"click":_vm.update}},'p',attrs,false),on))]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticStyle:{"position":"relative"}},[_c('v-form',{ref:"entryForm",staticStyle:{"position":"relative"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-card',{staticStyle:{"text-align":"center","margin-bottom":"20px"},attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"overline"},[_c('p',{staticStyle:{"font-size":"20px","margin":"0","font-weight":"bold"}},[_vm._v(" Lot add/edit ")])])],1),_c('v-row',{staticStyle:{"padding":"15px"},attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"padding":"0"},attrs:{"cols":"12"}},[_c('div',{staticStyle:{"padding":"0 10px"}},[_c('v-col',{staticStyle:{"padding":"0"},attrs:{"cols":"6"}},[_c('p',{staticStyle:{"margin":"0","font-size":"12px","font-weight":"bold","color":"rgba(0, 0, 0, 0.5)"}},[_vm._v(" Lot ")])]),_c('v-col',{staticStyle:{"padding":"0"},attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","rules":_vm.totalLotRules},model:{value:(_vm.form.lot_no),callback:function ($$v) {_vm.$set(_vm.form, "lot_no", $$v)},expression:"form.lot_no"}})],1)],1)]),_c('v-col',{staticStyle:{"padding":"0"},attrs:{"cols":"12"}},[_c('div',{staticStyle:{"padding":"0 10px"}},[_c('v-col',{staticStyle:{"padding":"0"},attrs:{"cols":"6"}},[_c('p',{staticStyle:{"margin":"0","font-size":"12px","font-weight":"bold","color":"rgba(0, 0, 0, 0.5)"}},[_vm._v(" Plant ")])]),_c('v-col',{staticStyle:{"padding":"0"},attrs:{"cols":"12"}},[_c('v-autocomplete',{staticStyle:{"margin":"0"},attrs:{"items":_vm.getDropdownPlant,"item-text":"name","item-value":"id","return-id":"","outlined":"","readonly":!_vm.isAdminHO,"disabled":_vm.getUserProfile.level.find(
                      function (ref) {
                                          var id = ref.id;

                                          return id === '1' || id === '29';
}
                    ) === undefined},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1)],1)]),_c('v-col',{staticStyle:{"padding":"0"},attrs:{"cols":"12"}},[_c('div',{staticStyle:{"padding":"0 10px"}},[_c('v-col',{staticStyle:{"padding":"0"},attrs:{"cols":"6"}},[_c('p',{staticStyle:{"margin":"0","font-size":"12px","font-weight":"bold","color":"rgba(0, 0, 0, 0.5)"}},[_vm._v(" Lokasi ")])]),_c('v-col',{staticStyle:{"padding":"0"},attrs:{"cols":"12"}},[_c('v-autocomplete',{staticStyle:{"margin":"0"},attrs:{"items":_vm.getDropdownLocation,"item-text":"name","item-value":"id","return-object":"","outlined":"","dense":"","rules":_vm.locationRules},model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}})],1)],1)]),_c('v-col',{staticStyle:{"padding":"0"},attrs:{"cols":"12"}},[_c('div',{staticStyle:{"padding":"0 10px"}},[_c('v-col',{staticStyle:{"padding":"0"},attrs:{"cols":"6"}},[_c('p',{staticStyle:{"margin":"0","font-size":"12px","font-weight":"bold","color":"rgba(0, 0, 0, 0.5)"}},[_vm._v(" Tanggal pembelian ")])]),_c('v-col',{staticStyle:{"padding":"0"},attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"clearable":"","dense":"","outlined":"","type":"date","name":"date","step":"1","rules":_vm.purchaseDateRules},model:{value:(_vm.form.purchase_date),callback:function ($$v) {_vm.$set(_vm.form, "purchase_date", $$v)},expression:"form.purchase_date"}})],1)],1)])],1),_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"text-align":"center"},attrs:{"flat":""}},[_c('v-btn',{staticClass:"ma-2",staticStyle:{"width":"45%"},attrs:{"color":"primary","type":"submit"}},[_vm._v(" simpan ")]),_c('v-btn',{staticClass:"ma-2",staticStyle:{"width":"45%"},attrs:{"color":"error"},on:{"click":_vm.close}},[_vm._v(" Batal ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }