<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="40%" @click:outside="clickOutside">
      <template v-slot:activator="{ on, attrs }">
        <p id="addLot" v-bind="attrs" v-on="on" @click="update"></p>
      </template>
      <v-card style="position:relative;">
        <v-form
          ref="entryForm"
          @submit.prevent="submit"
          style="position: relative;"
        >
          <v-card flat style="text-align:center;margin-bottom:20px;">
            <v-toolbar-title class="overline"
              ><p style="font-size: 20px; margin: 0; font-weight: bold;">
                Lot add/edit
              </p>
            </v-toolbar-title>
          </v-card>

          <v-row no-gutters style="padding: 15px;">
            <v-col cols="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="6" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Lot
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;">
                  <v-text-field
                    v-model="form.lot_no"
                    dense
                    outlined
                    :rules="totalLotRules"
                  />
                </v-col>
              </div>
            </v-col>

            <v-col cols="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="6" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Plant
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-autocomplete
                    v-model="company"
                    :items="getDropdownPlant"
                    item-text="name"
                    item-value="id"
                    return-id
                    outlined
                    :readonly="!isAdminHO"
                    :disabled="
                      getUserProfile.level.find(
                        ({ id }) => id === '1' || id === '29'
                      ) === undefined
                    "
                    style="margin: 0"
                  ></v-autocomplete>
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="6" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Lokasi
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-autocomplete
                    v-model="form.location"
                    :items="getDropdownLocation"
                    item-text="name"
                    item-value="id"
                    return-object
                    outlined
                    dense
                    style="margin: 0"
                    :rules="locationRules"
                  ></v-autocomplete>
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="6" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Tanggal pembelian
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;">
                  <v-text-field
                    v-model="form.purchase_date"
                    clearable
                    dense
                    outlined
                    type="date"
                    name="date"
                    step="1"
                    :rules="purchaseDateRules"
                  />
                </v-col>
              </div>
            </v-col>
          </v-row>
          <div
            flat
            style="text-align:center;"
            class="d-flex justify-center align-center"
          >
            <v-btn
              style="width:45%;"
              color="primary"
              type="submit"
              class="ma-2"
            >
              simpan
            </v-btn>

            <v-btn style="width:45%;" color="error" class="ma-2" @click="close">
              Batal
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import buildType from '../../../../services/buildType'
export default {
  props: ['assetDetail'],
  data() {
    return {
      asset: buildType.apiURL('asset'),
      dialog: false,
      company: null,
      form: {
        asset_id: '',
        location: null,
        lot_no: '',
        purchase_date: ''
      },
      purchaseDateRules: [],
      locationRules: [],
      totalLotRules: [],
      dropdownPlant: [],
      isAdminHO: false
    }
  },
  computed: {
    ...mapGetters(['getDropdownPlant', 'getDropdownLocation', 'getUserProfile'])
  },
  watch: {
    company() {
      this.dropdownLocation(this.company)
    }
  },
  methods: {
    ...mapActions(['dropdownLocation']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    submit() {
      this.purchaseDateRules = [v => !!v || 'Required']
      this.locationRules = [v => !!v || 'Required']
      this.totalLotRules = [v => !!v || 'Required']
      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const newForm = {
        act: 'add',
        asset_id: this.form.asset_id,
        location_id: this.form.location.id,
        location_name: this.form.location.name,
        lot_no: this.form.lot_no,
        purchase_date: this.form.purchase_date
      }
      console.log(newForm)
      this.save(newForm)
    },
    save(form) {
      axios
        .post(`${this.asset}asset/lot/save`, form)
        .then(res => {
          console.log(res)
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          this.$emit('loadPage')
          this.close()
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    clickOutside() {},
    update() {
      console.log(this.assetDetail)
      this.form.asset_id = this.assetDetail.id
      this.company = this.getUserProfile.employee.company.plant_id
      this.dropdownLocation(this.getUserProfile.employee.company.plant_id)
      this.isAdminHO = this.getUserProfile.employee.company.plant_id === '6'
    },
    close() {
      this.form = {
        asset_id: '',
        location: null,
        lot_no: '',
        purchase_date: ''
      }
      this.dialog = false
    }
  }
}
</script>
<style lang="scss" scoped></style>
